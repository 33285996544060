import englishFlag from '../images/icons/flags/united-kingdom.svg';

// TODO: uncomment when translation .json in i18n folder will be ready
// import franceFlag from '../images/icons/flags/france.svg';
// import germanyFlag from '../images/icons/flags/germany.svg';

export const navLinks = [
	{
		path: 'about-us',
		name: 'About Us'
	},
	{
		path: 'services',
		name: 'Services'
	},
	{
		path: 'industries',
		name: 'industries'
	},
	{
		path: 'team',
		name: 'Team'
	},
	{
		href: 'https://alpacatales.com',
		name: 'Blog'
	},
	{
		path: 'contact',
		name: 'Contact',
		button: true
	}
];

export const languages = [
	{
		name: 'English',
		code: 'en',
		img: englishFlag
	},
	// {
	// 	name: 'French',
	// 	code: 'fr',
	// 	img: franceFlag
	// },
	// {
	// 	name: 'German',
	// 	code: 'de',
	// 	img: germanyFlag
	// }
];

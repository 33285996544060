export const columnsFooter = [
	{
		name: 'Company',
		path: '/',
		links: [
			{
				name: 'About Us',
				path: 'about-us'
			},
			{
				name: 'Contact',
				path: 'contact'
			}
		]
	},
	{
		name: 'Our Services',
		path: 'services',
		links: [
			{
				name: 'Squish jump-start and boost',
				path: 'services#squish-kick-off'
			},
			{
				name: 'Automated GUI testing',
				path: 'services#automated-gui-testing'
			},
			{
				name: 'Embedded testing',
				path: 'services#embedded-testing'
			},
			{
				name: 'Mobile application testing',
				path: 'services#mobile-app-testing'
			},
			{
				name: 'Web testing',
				path: 'services#web-testing'
			},
			{
				name: 'Consulting and Training',
				path: 'services#consulting-training'
			},
			{
				name: 'Tool integration development',
				path: 'services#tool-integration-dev'
			}
		]
	},
	{
		name: 'Contact Us',
		path: 'contact',
		links: [
			{
				name: 'contact@cyberalpaca.com',
				mailto: 'contact@cyberalpaca.com'
			},
			{
				name: '+48 506 297 522',
				phone: '+48 506 297 522'
			}
		]
	}
];

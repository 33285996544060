export const contact = [
	{
		name: 'Phone number',
		value: '+48 506 297 522',
		src: 'tel:+48 506 297 522'
	},
	{
		name: 'email',
		value: 'contact@cyberalpaca.com',
		src: 'mailto:contact@cyberalpaca.com'
	},
	{
		name: 'address',
		value: "Jana Heweliusza 11/811, 80-890 Gdańsk, Poland",
		src: 'https://maps.app.goo.gl/DgYMg9aWbUa1LDkaA'
	}
];
